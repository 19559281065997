import React, { Component } from "react";

import "./index.sass";


class TypingText extends Component {
    constructor(props) {
        super(props)
        const {
            texts
        } = props

        this.state = {
            text: texts[0],
            currTextIndex: 0,
            currTextLetterDirection: "dec",
            currDelay: 0,
        };
    }

    DEFAULT_TICK = 50
    DEFAULT_DELAY = 40

    setText = () => {
        let {
            text,
            currTextIndex,
            currTextLetterDirection,
            currDelay
        } = this.state;
        let { texts, delay } = this.props;
            if (currDelay <= 0) {
                if (text.endsWith("|")) {
                    text = text.substring(0, text.length - 1);
                }
                if (currTextLetterDirection === "dec") {
                    if (text !== "") {
                        text = text.substring(0, text.length - 1);
                    } else {
                        currTextIndex++;
                        if (currTextIndex > texts.length - 1) {
                            currTextIndex = 0;
                        }
                        currTextLetterDirection = "inc";
                        text = texts[currTextIndex][0]
                    }
                } else {
                    if (text !== texts[currTextIndex]) {
                        text = texts[currTextIndex].substring(0, text.length + 1);
                    } else {
                        currDelay = delay || this.DEFAULT_DELAY;
                        currTextLetterDirection = "dec";
                    }
                }
            } else {
                if (currDelay % 8 == 0) {
                    if (text.endsWith("▐")) {
                        text = text.substring(0, text.length - 1);
                    } else {
                        text += "▐";
                    }
                }
                currDelay--;
            }
            const newState = {
                ...this.state,
                text: text,
                currTextIndex: currTextIndex,
                currTextLetterDirection,
                currTextLetterDirection,
                currDelay: currDelay,
            };
            this.setState(newState);
    }

    componentDidMount() {
        const { texts, tick } = this.props;
        if (texts && texts.length > 0) {
            this.placeholderInterval = setInterval(this.setText, tick || this.DEFAULT_TICK);
        }
    }

    componentWillUnmount() {
        if (this.placeholderInterval) {
            clearInterval(this.placeholderInterval);
        }
    }

    render() {
        const { className } = this.props;
        const { text } = this.state
        return (
            <span className={`typing-text${className ? " "+className : "" }`}>{text}</span>
        )
    }
}

export default TypingText