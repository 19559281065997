import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

import Layout from "@components/Layout";
import Jumbotron from "@components/Jumbotron";
import BenefitSection from "@components/BenefitSection";
import ProductCategoryBullet from "@components/ProductCategoryBullet";

import SearchBar from "@components/SearchBar";
import TypingText from "@components/TypingText";
import Slogan from "@components/Slogan";

import CharacterHome from "@img/char-home.svg";
import stage from "@img/stage.png";

import { nodeToProductCategory } from "@utils/product";
import { formatIntl } from "@utils/intl";

import "./index.sass";


// eslint-disable-next-line
export const IndexPageTemplate = ({
    featuredCategories
  }) => {
    const intl = useIntl();

    const HomeCTAText = [
        intl.formatMessage({id: "HomePage_CTA"},{index: 1}),
        intl.formatMessage({id: "HomePage_CTA"},{index: 2}),
        intl.formatMessage({id: "HomePage_CTA"},{index: 3})
    ];

    const SearchSuggestionText = [
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 1}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 2}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 3}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 4}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 5}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 6}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 7}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 8}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 9}),
        intl.formatMessage({id: "SearchBar_SUGGESTION"},{index: 10}),
    ];

  return (
    <div className="index-page">
      <Jumbotron ContentLeft={
        <Fragment>
            <Slogan animate />
            <main className="main-content shop-body">
                <h2 className="main-content-title">
                    <TypingText texts = {HomeCTAText}/>
                </h2>
                <SearchBar className="main-content-searchbar" placeholders={SearchSuggestionText} />
                <div className="main-content-items-wrapper">
                {
                  featuredCategories.map(({ id, slug, intl_title, icon_image, is_new, is_soon}) => (
                    <ProductCategoryBullet key={id} className="main-content-item" intl_title={intl_title} icon={icon_image} slug={slug}  is_new={is_new} is_soon={is_soon} />
                  ))
                }
                </div>
            </main>
        </Fragment>  
      }
      ContentRight={
        <Fragment>
          <div className="char-home">
              <CharacterHome />
          </div>
          <img className="char-home-stage" alt="stage" src={stage}/>
        </Fragment>   
      }
      />
      <BenefitSection />
    </div>
  );
};

const IndexPage = ({ data }) => {
  const featuredCategories = data.allMarkdownRemark.nodes.filter(
    node => node.frontmatter.templateKey == "ProductCategoryPage"
  ).map(nodeToProductCategory);

  return (
    <Layout>
      <IndexPageTemplate featuredCategories={featuredCategories} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
query IndexPageTemplate {
    allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: {
              in: ["ProductCategoryPage"]
            }
            is_featured: {
              eq: true
            }
            IS_DEFAULT: {
              ne: true
            }
            IS_HIDDEN: {
              ne: true
            }
          }}
    ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
              templateKey
              intl_title {
                en
                id
              }
              icon_image {
                childImageSharp {
                    gatsbyImageData(quality: 20, layout: CONSTRAINED, placeholder: BLURRED)
                }
              }
              productCategory
              is_new
              is_soon
          }
        }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
