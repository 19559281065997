import React from "react";

import "./index.sass"

const Slogan = ({ animate, className }) => (
    <h3 className={`slogan${animate ? " animate" : ""}${className? " " + className: ""}`}>
        <span className="slogan-title">Your Travel Companion</span>
        <span className="slogan-subtitle">to the Hi and Low</span>
    </h3>
);

export default Slogan;