import React, { useRef } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import useOnScreen from "@hooks/useOnScreen";

import Blob from "@img/blob.svg";

import "./index.sass";

const BenefitSection = () =>  {
    const ref = useRef(null);
    const isVisible =useOnScreen(ref)
    return (
        <div className={`benefit-section${isVisible ? " active": ""}`}>
            <div ref={ref} className="benefit-section-trigger" />
            <div className={`bg-ornament-benefit${isVisible ? " active": ""}`}>
                <Blob />
            </div>
            <h4 className="benefit-section-title">
                <FormattedMessage id="BenefitSection_TITLE" />
            </h4>
            <ul className="benefit-section-content">
                <div className="benefit-section-content-wrapper right">
                    <li className="benefit-section-content-item solution">
                        <div className="benefit-section-content-item-wrapper">
                            <h5 className="benefit-section-content-item-title">
                                <FormattedMessage id="BenefitSection_ITEMTITLE" values={{benefit: "solution"}} />
                            </h5>
                            <span className="benefit-section-content-item-subtitle">
                                <FormattedMessage id="BenefitSection_ITEMBODY" values={{benefit: "solution"}} />
                            </span>
                        </div>
                    </li>
                    <li className="benefit-section-content-item same-day">
                        <div className="benefit-section-content-item-wrapper">
                            <h5 className="benefit-section-content-item-title">
                                <FormattedMessage id="BenefitSection_ITEMTITLE" values={{benefit: "sameday"}} />
                            </h5>
                            <span className="benefit-section-content-item-subtitle">
                                <FormattedMessage id="BenefitSection_ITEMBODY" values={{benefit: "sameday"}} />
                            </span>
                        </div>
                    </li>
                </div>
                <div className="benefit-section-content-wrapper left">
                    <li className="benefit-section-content-item traveler">
                        <div className="benefit-section-content-item-wrapper">
                            <h5 className="benefit-section-content-item-title">
                                <FormattedMessage id="BenefitSection_ITEMTITLE" values={{benefit: "traveler"}} />
                            </h5>
                            <span className="benefit-section-content-item-subtitle">
                                <FormattedMessage id="BenefitSection_ITEMBODY" values={{benefit: "traveler"}} />
                            </span>
                        </div>
                    </li>
                    <li className="benefit-section-content-item trust">
                        <div className="benefit-section-content-item-wrapper">
                            <h5 className="benefit-section-content-item-title">
                                <FormattedMessage id="BenefitSection_ITEMTITLE" values={{benefit: "trust"}} />
                            </h5>
                            <span className="benefit-section-content-item-subtitle">
                                <FormattedMessage id="BenefitSection_ITEMBODY" values={{benefit: "trust"}} />
                            </span>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
    );
}

export default BenefitSection;