import React from "react";
import Blob from "@img/blob.svg";

import "./index.sass";
const Jumbotron = ({ ContentLeft, ContentRight }) => {
    return (
        <section className="jumbotron">
            <div className="bg-ornament bg-ornament-1">
                <Blob></Blob>
            </div>
            <div className="bg-ornament bg-ornament-2">
                <Blob></Blob>
            </div>
            <div className="bg-ornament bg-ornament-3">
                <Blob></Blob>
            </div>
            <div className="jumbotron-left">
                <div className="jumbotron-left-content-wrap">
                    {ContentLeft}
                </div>
            </div>
            <div className="jumbotron-right">
                <div className="jumbotron-right-img">
                    {ContentRight}
                </div>
            </div>
        </section>
    )
};

export default Jumbotron